.rd {
  &__layout-selector {
    &__option {
      display: flex;
      align-items: center;
      border-radius: var(--str-video__border-radius-lg);
      background-color: var(--str-video__button-primary-base);
      padding: 8px 16px;
      margin-top: 8px;
      gap: 8px;

      font-weight: 600;
      font-size: var(--str-video__font-size-md);

      &:hover {
        background-color: var(--str-video__button-primary-hover);
        cursor: pointer;
      }

      &--selected {
        background-color: var(--str-video__button-primary-active);
      }
    }

    &__icon {
      background-color: var(--str-video__icon-default);

      .rd__layout-selector__option:hover & {
        background-color: var(--str-video__icon-hover);
      }

      .rd__layout-selector__option--selected & {
        background-color: var(--str-video__icon-active);
      }
    }
    &__label {
      color: var(--str-video__base-color2);
      .rd__layout-selector__option:hover & {
        color: var(--str-video__base-color1);
      }

      .rd__layout-selector__option--selected & {
        color: var(--str-video__base-color1);
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      list-style: none;
      margin: 0;
      gap: var(--str-video__spacing-xs);
      padding: var(--str-video__spacing-lg);
    }
  }
}
