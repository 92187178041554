.rd__documentation-button__menu {
  display: flex;
  padding: var(--str-video__spacing-lg);
  flex-direction: column;
  margin: 0;
  gap: var(--str-video__spacing-md);
  border-radius: var(--str-video__border-radius-lg);
  max-width: 400px;

  background-color: var(--str-video__base-color6);
}

.rd__call-header__title {
  display: none;
  .str-video__call-controls__button .str-video__icon--caret-down {
    width: 18px;
    height: 18px;
  }

  .str-video__composite-button__button-group {
    width: 18px;
    height: 18px;
    padding: 0;
    display: flex;
    justify-content: center;
  }
}

@include respond-above(sm) {
  .rd__call-header__title {
    display: flex;
  }
}

.rd__documentation-button__heading {
  font-size: var(--str-video__font-size-md);
  margin: 0;
}

.rd__documentation-button__description {
  color: var(--str-video__text-color3);
  font-size: var(--str-video__font-size-sm);
  margin: 0;
}

.rd__documentation-button__actions {
  display: flex;
  justify-content: space-between;
  gap: var(--str-video__spacing-md);
}
