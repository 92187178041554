.rd {
  &__tabmodal-container {
    display: flex;
    border-radius: calc(
      // +2px to fix border radius issue
      var(--str-video__border-radius-lg) + 2px
    );
    width: 90vw;
    min-height: 65vh;
    border: 2px solid var(--str-video__base-color6);

    box-shadow: 0 14px 34px rgba(0, 0, 0, 0.75);
  }

  &__tabmodal-header {
    font-size: 20px;
    font-weight: 500;
    padding-left: var(--str-video__spacing-md);
    display: none;
  }

  &__tab-panel {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__tabmodal-sidebar {
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
    min-width: 100px;

    background-color: var(--str-video__base-color5);
    border-radius: var(--str-video__border-radius-lg) 0 0
      var(--str-video__border-radius-lg);
    padding: var(--str-video__spacing-md) var(--str-video__spacing-md)
      var(--str-video__spacing-md) 0;
  }

  &__tabmodal-content {
    background-color: var(--str-video__base-color7);
    border-radius: 0 var(--str-video__border-radius-lg)
      var(--str-video__border-radius-lg) 0;
    padding: var(--str-video__spacing-md);
    width: 100%;
    max-height: 65vh;
    overflow: hidden;
  }

  &__tab-panel__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--str-video__spacing-md);
  }

  &__tab-panel__heading {
    font-size: 20px;
    font-weight: 500;
    margin-right: auto;
  }

  &__tab-panel__close {
    background-color: unset;
  }

  &__tab-panel__content {
    height: 65vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    .rd__video-effects {
      padding: var(--str-video__spacing-lg);

      .rd__video-effects__list {
        padding-top: var(--str-video__spacing-md);
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        .str-video__composite-button__button-group {
          width: 48px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;

          .str-video__icon {
            width: 2rem;
            height: 2rem;
          }
        }

        .rd__video-effects__blur--medium {
          .str-video__icon {
            width: 1.7rem;
            height: 1.7rem;
          }
        }

        .rd__video-effects__blur--low {
          .str-video__icon {
            width: 1.25rem;
            height: 1.25rem;
          }
        }
      }

      .rd__video-effects__preview-container {
        display: flex;
        flex-direction: column;
        min-height: 320px;
        max-width: 480px;

        .str-video__video-preview-container {
          width: 100%;
        }
      }

      .rd__video-effects__image {
        width: 180px;
        height: 100%;
        aspect-ratio: 16 / 9;
        object-fit: cover;
        border: 1px solid var(--str-video__base-color6);
        border-radius: var(--str-video__border-radius-xs);
        display: inline-block;

        &:hover {
          border-color: var(--str-video__brand-color1);
        }

        &--active {
          border-color: var(--str-video__brand-color1);
        }
      }
    }
  }

  &__tab {
    display: flex;
    align-items: center;
    padding: var(--str-video__spacing-sm) var(--str-video__spacing-md);

    font-size: 16px;
    margin-bottom: var(--str-video__spacing-sm);
    font-weight: 500;
    border-radius: 0 var(--str-video__border-radius-md)
      var(--str-video__border-radius-md) 0;

    &-icon {
      margin-right: var(--str-video__spacing-sm);
      height: 18px;
      width: 18px;
    }

    &-label {
      display: none;
    }

    &:first-of-type {
      margin-top: 40px;
    }

    &:hover {
      cursor: pointer;
      background-color: var(--str-video__button-default-hover);
    }

    &--active {
      background-color: var(--str-video__button-default-active);
      font-weight: 600;
    }
  }
}

@include respond-above(sm) {
  .rd {
    &__tabmodal-container {
      width: 65vw;
    }

    &__tabmodal-header {
      display: flex;
    }

    &__tabmodal-sidebar {
      width: 100vw;
    }

    &__tab {
      padding: var(--str-video__spacing-md);
      &-label {
        display: block;
      }
    }

    &__tabmodal-sidebar {
      width: 300px;
      flex-direction: column;
    }

    &__tab-panel {
      &__header {
        justify-content: flex-end;
      }
      &__heading {
        display: none;
      }
    }

    &__tab-panel__content {
      .rd__video-effects {
      }
    }
  }
}

@include respond-above(lg) {
  .rd {
    &__tabmodal-container {
      width: 55vw;
    }
  }
}

@include respond-above(xlg) {
  .rd {
    &__tabmodal-container {
      width: 45vw;
    }
  }
}
