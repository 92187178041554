.rd__leave {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: var(--str-video__spacing-lg);

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;

    .rd__feedback {
      box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.75);
    }
  }
}

@include respond-above(sm) {
  .rd__leave-content {
    max-width: 35vw;
  }
}
