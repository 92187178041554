.rt__delete-account {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;

  a {
    color: var(--str-video__brand-color1);
    text-decoration: none;
  }
}
