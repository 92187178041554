.rd__debug__track-stats {
  font-size: 0.6rem;
  overflow: scroll;
  height: 600px;
  width: 500px;
  background-color: var(--str-video__background-color1);
  color: var(--str-video__text-color1);
  z-index: 10;
}

.rd__debug__track-stats-icon {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background-position: center;
  background-repeat: no-repeat;
  background-image: var(--str-video__icon--stats);
  cursor: pointer;
}

.rd__debug-participant-view {
  width: 100%;
  height: 100%;
  position: relative;
}

.rd__debug__extra {
  position: absolute;
  display: flex;
  bottom: 0.875rem;
  right: 0.875rem;
  padding: 4px;
  background-color: var(--str-video__background-color1);
  border-radius: var(--str-video__border-radius-xs);
}
