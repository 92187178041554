.rd__tour {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  border-radius: var(--str-video__border-radius-lg);
  background-color: var(--str-video__base-color6);
  box-shadow: 0 0 24px -4px rgba(0, 0, 0, 0.64);
  padding: var(--str-video__spacing-lg);
  gap: var(--str-video__spacing-lg);

  z-index: 10;
  width: max-content;
  max-width: 300px;
  top: 0;
  left: 0;

  transition-property: transform;
  transition-timing-function: ease-in;
  transition-duration: 0.2s;

  &.rd__tour--first-step {
    transition-duration: 0s;
  }
}

.rd__tour__content-container {
  position: relative;
  padding: 5px;
}

.rd__tour__step-overlay {
  backdrop-filter: blur(6px);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 8px;
}

.rd__tour__image-container {
  width: 100%;
  height: auto;
}

.rd__tour__image {
  object-fit: cover;
  width: 100%;
  border-radius: var(--str-video__border-radius-lg);
  background-color: var(--str-video__base-color7);
}

.rd__tour__header {
  font-size: var(--str-video__font-size-md);
  font-weight: 600;
  margin: 0;
  padding-bottom: 15px;
}

.rd__tour__explanation {
  font-size: var(--str-video__font-size-sm);
  margin-bottom: 0;
  color: var(--str-video__text-color2);
}

.rd__tour__link {
  font-size: var(--str-video__font-size-sm);
  color: var(--str-video__text-color1);
}

.rd__tour__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: var(--str-video__spacing-sm);

  &--last-step {
    flex-direction: column;
  }
}

.rd__tour__dont-show {
  display: flex;
  width: 100%;
  gap: var(--str-video__spacing-md);
  margin-top: var(--str-video__spacing-sm);
  font-size: var(--str-video__font-size-xs);
  align-items: center;
}

.rd__tour__indicator {
  width: 0;
  height: 0;
  border-style: solid;
  transform: rotate(0deg);
  position: absolute;

  &--bottom-start,
  &--bottom-end,
  &--bottom {
    top: 0;
    margin-top: -9px;
    border-width: 0 7px 10px 7px;
    border-color: transparent transparent var(--str-video__base-color6)
      transparent;
  }

  &--left-start {
    right: 0;
    top: 25px;
    margin-right: -10px;
    border-width: 7px 0 7px 10px;
    border-color: transparent transparent transparent
      var(--str-video__base-color6);
  }

  &--left-end {
    right: 0;
    bottom: 35px;
    margin-right: -10px;
    border-width: 7px 0 7px 10px;
    border-color: transparent transparent transparent
      var(--str-video__base-color6);
  }

  &--right-start {
    left: 0;
    top: 25px;
    margin-left: -10px;
    border-width: 7px 10px 7px 0;
    border-color: transparent var(--str-video__base-color6) transparent
      transparent;
  }

  &--top {
    margin-bottom: -9px;
    bottom: 0;
    border-width: 10px 7px 0 7px;

    border-color: var(--str-video__base-color6) transparent transparent
      transparent;
  }
}

.rd__sdk-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--str-video__spacing-md);
  margin-top: var(--str-video__spacing-md);
}

.rd__sdk-options__option {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1d2f34;
  color: #00e2a1;
  padding: var(--str-video__spacing-xs);
  border-radius: var(--str-video__border-radius-xs);
  gap: var(--str-video__spacing-sm);
  width: 100%;
  max-width: 130px;
  font-size: var(--str-video__font-size-xs);
  font-weight: 600;

  .str-video__icon {
    width: 20px;
    height: 20px;
    background-color: #00e2a1;
  }
}
