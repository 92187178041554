.rd__input,
.rd__textarea {
  background-color: var(--str-video__base-color4);
  border-radius: var(--str-video__border-radius-md);
  padding: var(--str-video__spacing-xs) var(--str-video__spacing-md);
  border: 1px solid var(--str-video__base-color3);
  color: var(--str-video__base-color1);
  width: 100%;

  &:focus,
  &:focus-visible {
    border: 1px solid var(--str-video__base-color1);
  }
}
