.rd__error {
  display: flex;
  height: 100%;
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    padding: var(--str-video__spacing-lg);
  }

  &__message {
    pre {
      padding: var(--str-video__spacing-md);
      background: var(--str-video__base-color-6);
      border: 1px solid var(--str-video__base-color1);
      border-radius: var(--str-video__border-radius-sm);
      color: var(--str-video__text-color1);
    }

    p {
      margin: 0;
      font-size: var(-str-video__font-size-xs);
    }
  }

  &__actions {
    display: flex;
    width: 100%;
    max-width: 60%;
    gap: var(--str-video__spacing-md);
  }
}
