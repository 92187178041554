.rd {
  &__participants-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: var(--str-video__border-radius-lg);
    width: 25vw;
    background-color: var(--str-video__base-color5);
    padding: var(--str-video__spacing-lg);

    box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.75);
  }

  &__participants-preview__button {
    position: relative;
  }

  &__participants-preview__count {
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--str-video__border-radius-circle);
    position: absolute;

    top: -3px;
    right: -3px;
    background: var(--str-video__brand-color3);
    color: var(--str-video__base-color4);
    padding: 5px;
    height: 16px;
    width: 16px;
  }

  &__participants-preview__heading {
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    text-align: center;
  }

  &__participants-preview__description {
    font-size: var(--str-video__font-size-sm);
    font-weight: 400;
    margin-bottom: var(--str-video__spacing-md);
    width: 70%;
    text-align: center;
  }

  &__participants-preview__call-preview {
    display: flex;
    flex-direction: column;
    background-color: var(--str-video__base-color7);
    border-radius: 0 var(--str-video__border-radius-lg)
      var(--str-video__border-radius-lg) 0;
    padding: var(--str-video__spacing-md);
    width: 100%;
  }
}
