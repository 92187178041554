.rd__layout {
  display: flex;
  height: calc(100vh - 140px);
  height: calc(100dvh - 140px);
  gap: var(--str-video__spacing-md);
  padding: var(--str-video__spacing-md);
  position: relative;

  .rd__layout__stage-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
  }

  .str-video__speaker-layout__wrapper,
  .str-video__paginated-grid-layout__wrapper {
    flex: 1;
  }
}

.rd__notifications {
  position: absolute;
  bottom: 60px;
  left: var(--str-video__spacing-lg);
  right: 0;
  z-index: 3;
}

.rd__sidebar {
  position: absolute;
  border-radius: var(--str-video__border-radius-md);
  height: calc(100vh - 60px);
  height: calc(100dvh - 60px);

  &.rd__sidebar--open {
    z-index: 2;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .rd__sidebar__container {
    flex-grow: 1;
    padding: var(--str-video__spacing-lg) 0;
    background-color: var(--str-video__base-color7);
  }

  .str-video__participant-list {
    flex-grow: 1;
    height: calc(100vh - 550px);
    height: calc(100dvh - 550px);
  }

  .str-video__chat {
    display: flex;
    flex-direction: column;
    background-color: var(--str-video__base-color7);
    border-radius: var(--str-video__border-radius-md);
    height: 100%;
    width: 100%;
  }
}

.rd__sidebar__call-stats {
  display: flex;
  height: 100%;
  overflow-y: scroll;
}

.rd__participants {
  display: flex;
  flex-direction: column;
  background-color: var(--str-video__base-color7);
  border-radius: var(--str-video__border-radius-md);
  padding: var(--str-video__spacing-lg);
  height: 100%;
  width: 100%;
}

@include respond-above(sm) {
  .rd__sidebar {
    position: relative;
    width: 0;
    height: auto;
    overflow: hidden;
    transition-property: width;
    transition-duration: 0.3s;

    &.rd__sidebar--open {
      width: 350px;
    }

    .rd__sidebar__container {
      max-width: 350px;
      min-width: 350px;
      display: flex;
      gap: var(--str-video__spacing-md);
      height: 100%;
      padding: 0;
    }

    .str-video__chat {
      margin: 0;
    }
  }

  .rd__participants {
    margin: 0;

    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.rd__highlight {
  box-shadow: 0px 0px 16px 4px #005fff;
}

@include respond-above(sm) {
  .rd__sidebar {
    z-index: 1;
  }
}
