.rd__closed-captions {
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rd__closed-captions__line {
  margin: 0;
  font-size: var(--str-video__font-size-md);
}

.rd__closed-captions__speaker {
  color: var(--str-video__text-color3);
}

.rd__closed-captions-sidebar {
  display: flex;
  flex-direction: column;

  padding: var(--str-video__spacing-md);

  .rd__closed-captions-sidebar__container {
    overflow-y: scroll;
    flex: 1;
    padding-bottom: var(--str-video__spacing-lg);

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    .rd__closed-captions__line {
      line-height: 1.7;
      font-size: var(--str-video__font-size-sm);
    }
  }
}
