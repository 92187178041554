.str-video__speaker-layout {
  .rd__framer-participant-view-wrapper {
    position: absolute;
    width: 200px;
    top: 0.875rem;
    right: 0.875rem;
    z-index: 1;

    .str-video__video-placeholder {
      background-color: var(--str-video__base-color6);
    }

    &:hover {
      cursor: move;
    }
  }
}

@include respond-above(sm) {
  .str-video__speaker-layout {
    .rd__framer-participant-view-wrapper {
      width: 320px;
    }
  }
}
