@use 'sass:math';

.str-video__call-participants-view {
  display: grid;
  grid-auto-rows: auto min-content;
  grid-column-gap: 0.625rem;
  grid-row-gap: 0.625rem;
  align-items: center;
  justify-items: center;
  height: 100%;
  min-height: 0;

  &.str-video__grid-1 {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
  &.str-video__grid-2 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
  &.str-video__grid-3 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  &.str-video__grid-4 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  &.str-video__grid-5 {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  &.str-video__grid-6 {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  &.str-video__grid-7 {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  &.str-video__grid-8 {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  &.str-video__grid-9 {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  &.str-video__grid-10 {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  &.str-video__grid-11 {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  &.str-video__grid-12 {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
  }

  $start: 13;
  $end: 50;

  @for $i from $start through $end {
    &.str-video__grid-#{$i} {
      grid-template-columns: repeat(if($i <= 25, 5, 6), 1fr);
      grid-auto-rows: 1fr;
    }
  }
}
