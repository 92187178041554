.rd__call-header {
  display: flex;
  align-items: center;

  justify-content: space-between;

  background-color: var(--str-video__base-color7);
  border-radius: var(--str-video__border-radius-xxl);

  padding: var(--str-video__spacing-xl);

  z-index: 2;

  &--transparent {
    background-color: transparent;
  }

  .rd__call-header__title-group {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 1rem;

    a[data-testid='home-button'] {
      display: flex;
    }

    .rd__call-header-title {
      white-space: nowrap;
      overflow-y: visible;
      // Not every browser support clip overflow, so hidden is defined as a fallback
      overflow-x: hidden;
      overflow-x: clip;
      text-overflow: ellipsis;
      font-size: var(---str-video__font-size-md);
      font-weight: 400;
      margin: 0;
      max-width: 400px;
    }
  }
  &--active {
    .rd__call-header__title-group {
      display: none;
    }
  }
}

.rd__call-header__title {
  display: flex;
  align-items: center;
  gap: var(--str-video__spacing-md);
}

.rd__call-header__documentation {
  display: flex;
  gap: var(--str-video__spacing-md);
  align-items: center;

  .rd__button {
    font-size: var(--str-video__font-size-xs);
    padding: var(--str-video__spacing-xs) var(--str-video__spacing-md);
  }
}

.rd__call-header__controls-group {
  display: flex;
  gap: 0.5rem;
}

.rd__header__elapsed {
  display: flex;
  justify-content: center;
  align-items: center;
  font-variant: tabular-nums;
  background-color: var(--str-video__base-color6);
  padding: var(--str-video__spacing-xxs) var(--str-video__spacing-md);
  border-radius: var(--str-video__border-radius-md);
  font-size: var(--str-video__font-size-xs);
  &-icon {
    margin-right: var(--str-video__spacing-xxs);
    background-color: var(--str-video__alert-success);
    height: 16px;
  }

  &-time {
    font-weight: 600;
  }
}

.rd__header__latency {
  display: none;
  justify-content: center;
  align-items: center;
  font-variant: tabular-nums;
  background-color: var(--str-video__base-color6);
  padding: var(--str-video__spacing-xxs) var(--str-video__spacing-md);
  border-radius: var(--str-video__border-radius-md);
  font-size: var(--str-video__font-size-xs);

  font-weight: 600;

  &-indicator {
    height: 6px;
    width: 6px;
    margin-right: var(--str-video__spacing-xxs);
    background-color: var(--str-video__alert-success);

    border-radius: var(--str-video__border-radius-circle);
    &--good {
      background-color: var(--str-video__alert-success);
    }
    &--ok {
      background-color: var(--str-video__alert-caution);
    }

    &--bad {
      background-color: var(--str-video__alert-warning);
    }
  }
}

.rd__call-header__notifications {
  display: flex;
  justify-content: center;

  .rd__loading-indicator {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  .rd__loading-indicator__icon {
    width: 1rem;
    height: 1rem;
  }
  .rd__loading-indicator-text {
    font-size: 0.875rem;
  }
}

.rd__user-session {
  display: flex;
  align-items: center;
  gap: var(--str-video__spacing-md);

  &__container {
    display: flex;
    align-items: center;
    gap: var(--str-video__spacing-md);
  }

  &__user {
    display: none;
    flex-direction: column;
    font-size: var(--str-video__font-size-xs);

    &:hover {
      cursor: pointer;
    }
  }

  &__name {
    margin: 0;
    font-weight: 600;
  }

  &__email {
    margin: 0;
  }

  &__menu {
    border-radius: var(--str-video__border-radius-lg);
    background-color: var(--str-video__base-color7);

    &-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: var(--str-video__spacing-md);
      font-size: var(--str-video__font-size-xs);
    }

    &-item {
      display: flex;

      .str-video__dropdown-selected__label {
        padding: var(--str-video__spacing-sm) var(--str-video__spacing-md);
      }
    }

    .rd__button.rd__user-session__menu-button {
      font-size: var(--str-video__font-size-xs);
    }
  }

  &__toggle {
    background-color: transparent;
  }
}

@include respond-above(sm) {
  .rd__call-header--active {
    .rd__call-header__settings {
      display: none;
    }
    .rd__call-header__leave {
      display: none;
    }
    .rd__call-header__title-group {
      display: flex;
    }
  }

  .rd__call-header__controls-group {
    > * {
      &:nth-child(3) {
        display: none;
      }
    }
  }

  .rd__header__latency {
    display: flex;
  }

  .rd__user-session {
    &__user {
      display: flex;
    }
  }
}
